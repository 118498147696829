/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onNewAlarmData = /* GraphQL */ `subscription OnNewAlarmData($siteId: Int!) {
  onNewAlarmData(siteId: $siteId) {
    ackSource
    ackTimestamp
    alarmTimestamp
    clearTimestamp
    description
    isActive
    siteId
    source
    value
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnNewAlarmDataSubscriptionVariables,
  APITypes.OnNewAlarmDataSubscription
>;
export const onNewChargerData = /* GraphQL */ `subscription OnNewChargerData($chargerId: Int, $connector: Int, $siteId: Int) {
  onNewChargerData(
    chargerId: $chargerId
    connector: $connector
    siteId: $siteId
  ) {
    chargerCmdHasError
    chargerCmdResult
    chargerCmdSeqNr
    chargerId
    connector
    current
    energyMeter
    offeredCurrent
    offeredPower
    pingResult
    power
    sessionEnergy
    siteId
    soc
    timestamp
    voltage
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnNewChargerDataSubscriptionVariables,
  APITypes.OnNewChargerDataSubscription
>;
export const onNewChargerStatusNotification = /* GraphQL */ `subscription OnNewChargerStatusNotification(
  $chargerId: Int
  $connector: Int
  $siteId: Int
) {
  onNewChargerStatusNotification(
    chargerId: $chargerId
    connector: $connector
    siteId: $siteId
  ) {
    chargerId
    connector
    errorCode
    id
    info
    siteId
    source
    statusCode
    timestamp
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnNewChargerStatusNotificationSubscriptionVariables,
  APITypes.OnNewChargerStatusNotificationSubscription
>;
export const onNewChargingSessionData = /* GraphQL */ `subscription OnNewChargingSessionData($rfid: String, $siteId: Int) {
  onNewChargingSessionData(rfid: $rfid, siteId: $siteId) {
    chargerId
    connector
    costs
    id
    rfid
    siteId
    startDate
    startMeter
    stopDate
    stopMeter
    stopReason
    tariffId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnNewChargingSessionDataSubscriptionVariables,
  APITypes.OnNewChargingSessionDataSubscription
>;
export const onNewReport = /* GraphQL */ `subscription OnNewReport($siteId: Int!) {
  onNewReport(siteId: $siteId) {
    customization
    error
    outputFormat
    reportId
    result
    siteId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnNewReportSubscriptionVariables,
  APITypes.OnNewReportSubscription
>;
export const onNewTrendData = /* GraphQL */ `subscription OnNewTrendData($siteId: Int!) {
  onNewTrendData(siteId: $siteId) {
    chargerId
    costs
    energy
    energymeter
    energyoffrel
    maxdisabled
    pmax
    power
    regperiod
    siteId
    soc
    targetenergy
    tariff
    timestamp
    tlId
    trendpmax
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnNewTrendDataSubscriptionVariables,
  APITypes.OnNewTrendDataSubscription
>;
export const onUpdateLiveData = /* GraphQL */ `subscription OnUpdateLiveData($siteId: Int!) {
  onUpdateLiveData(siteId: $siteId) {
    chargerAuth
    chargerCurI
    chargerCurP
    chargerCurU
    chargerDevMaxI
    chargerESession
    chargerETotal
    chargerSetMaxI
    chargerState
    commandResult
    commandSeqNr
    curConditionStatus
    curEnergy
    curEnergyMeterValues
    curInputStatus
    curModuleStatus
    curOutputStatus
    curOutputStatusPhysical
    curPower
    curTimeOfPeriod
    curTrendLevel
    pingResult
    regulationPriorityStatus
    siteId
    targetValues
    timestamp
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLiveDataSubscriptionVariables,
  APITypes.OnUpdateLiveDataSubscription
>;
