import jQuery from "jquery";

const $j = jQuery.noConflict();
const KEY_ENTER = 13;
const KEY_ESCAPE = 27;

export class KeyboardHandler {
  constructor(id) {
    this.id = id;
    this.keyboard = $j(`#${id}`);
    this.$jwrite = $j(".write", this.keyboard);
    this.$jlabel = $j(".label", this.keyboard);
    this.$background = $j("#keyboard_background");
    this.shift = false;
    this.capslock = false;
    this.isShown = false;
    this.$jel = null; // reference to input field (only valid as long as keyboard is shown)
    this.target = this.$jwrite[0];

    // Close with esc key & clicking outside
    $j(document).on("mousedown.keyboard keyup.keyboard", (e) => {
      if (this.escClose(e) === false) return false;
    });

    $j("li", this.keyboard).on("mousedown", (event) => {
      this.handleMouseEvent(event.currentTarget);
    });

    // put cursor at end
    jQuery.fn.putCursorAtEnd = function () {
      return this.each(function () {
        $j(this).focus();

        // If this function exists...
        if (typeof this.setSelectionRange !== "undefined") {
          // ... then use it
          // (Doesn't work in IE)

          // Double the length because Opera is inconsistent about whether a carriage return is one character or two. Sigh.
          const len = $j(this).val().length * 2;
          this.setSelectionRange(len, len);
        } else {
          // ... otherwise replace the contents with itself
          // (Doesn't work in Google Chrome)
          $j(this).val($j(this).val());
        }

        // Scroll to the bottom, in case we're in a tall textarea
        // (Necessary for Firefox and Google Chrome)
        this.scrollTop = 999999;
      });
    };

    this.hide();
  }

  handleMouseEvent(element) {
    const $jthis = $j(element);
    let character = $jthis.html(); // If it's a lowercase letter, nothing happens to this variable

    // this needs jQuery-ui, which we want to avoid
    // $jthis.effect("highlight", { color: "#000000" }, 300);
    // use this instead:
    $jthis.addClass("highlight");
    setTimeout(() => {
      $jthis.removeClass("highlight");
    }, 200); // keep timeout konsistent with animation time

    // Accept
    if ($jthis.hasClass("accept")) {
      this.accept();
      return false;
    }
    // Cancel
    if ($jthis.hasClass("cancel")) {
      this.cancel();
      return false;
    }
    // Shift keys
    if ($jthis.hasClass("shift")) {
      $j(".letter", this.keyboard).toggleClass("uppercase");
      $j(".symbol span", this.keyboard).toggle();

      this.shift = this.shift === true ? false : true;
      this.capslock = false;
      return false;
    }
    // Caps lock
    if ($jthis.hasClass("capslock")) {
      $j(".letter", this.keyboard).toggleClass("uppercase");
      this.capslock = true;
      return false;
    }
    // letter-larr
    if ($jthis.hasClass("changesign")) {
      const html = this.getContent(this.$jwrite);
      if (html.substr(0, 1) === "-") {
        this.setContent(this.$jwrite, html.slice(1));
      } else {
        this.setContent(this.$jwrite, `-${html}`);
      }
      return false;
    }

    // if other content ...
    // max one
    if ($jthis.hasClass("maxone")) {
      const html = this.getContent(this.$jwrite);
      if (html.indexOf(character) !== -1) return false;
    }
    // Delete
    if ($jthis.hasClass("delete")) {
      this.singleClickBackButton();
      return false;
    }
    // Special characters
    if ($jthis.hasClass("symbol")) character = $j("span:visible", $jthis).html();
    if ($jthis.hasClass("space")) character = " ";
    if ($jthis.hasClass("tab")) character = "\t";
    if ($jthis.hasClass("return")) character = "\n";
    // Uppercase letter
    if ($jthis.hasClass("uppercase")) character = character.toUpperCase();

    // Remove shift once a key is clicked.
    if (this.shift === true) {
      $j(".symbol span", this.keyboard).toggle();
      if (this.capslock === false) $j(".letter", this.keyboard).toggleClass("uppercase");

      this.shift = false;
    }
    // Add the character
    this.addCharacter(character);
  }

  reveal(el) {
    if ($j(el).hasClass("write")) return;
    this.$jel = $j(el);

    const txt = this.getLabelText();
    if (txt == null) {
      this.$jlabel.html("");
      this.keyboard.removeClass("keyboardWithTitle");
    } else {
      this.$jlabel.html(txt);
      this.keyboard.addClass("keyboardWithTitle");
    }

    this.show();
    this.setContent(this.$jwrite, this.getContent(this.$jel));
  }

  cancel() {
    this.hide();
  }

  accept() {
    this.setContent(this.$jel, this.getContent(this.$jwrite));
    try {
      // it would be 'parentWindow' instead of 'defaultView' in IE, but keyboard is for FF only
      const $jc = this.$jel[0].ownerDocument.defaultView.$j;
      $jc(this.$jel[0]).trigger("change");
    } catch (e) {
      // nothing to do
    }
    this.hide();
  }

  escClose(e) {
    // ignore autoaccept if using escape - good idea?
    if (this.isShown === false) {
      return;
    }
    if (e.type === "keyup" && e.which === KEY_ESCAPE) {
      this.cancel();
    }
    if (
      e.type === "keyup" &&
      e.which === KEY_ENTER &&
      this.keyboard.hasClass("containertextarea") === false
    ) {
      this.accept();
    }
    // disable close when clicked outside
    if (e.target !== this.$jel && $j(e.target).closest(`#${this.id}`).length < 1) {
      this.cancel();
    }
  }

  getLabelText() {
    if (this.$jel.attr("title") != null) return this.$jel.attr("title");
    return $j("td.label", this.$jel[0].parentNode.parentNode).html();
  }

  show() {
    const windowWidth = document.documentElement.clientWidth;
    const popupWidth = $j(this.keyboard).width();
    // centering
    $j(this.keyboard).css({
      position: "fixed",
      // "top": windowHeight/2-popupHeight/2 + 59,
      bottom: 0,
      left: windowWidth / 2 - popupWidth / 2,
    });
    this.isShown = true;
    this.keyboard.show();
    this.$background.show();
    this.$jwrite.putCursorAtEnd();
  }

  hide() {
    this.isShown = false;
    this.$background.hide();
    this.keyboard.hide();
    if (this.$jel != null && typeof this.$jel[0].onKeyboardClose === "function") {
      try {
        this.$jel[0].onKeyboardClose();
      } catch (e) {
        // nothing to do
      }
    }

    // reset keyboard
    this.reset();
  }

  reset() {
    // erase reference!
    this.$jel = null;

    // i dunno how to remove shift...
    if (this.capslock) $j(".letter", this.keyboard).toggleClass("uppercase");
    this.capslock = false;
  }

  getContent($jel) {
    return $jel.val();
  }

  setContent($jel, content) {
    // cut to maxlength
    const maxLength = $jel.attr("maxlength");
    if (content.length > maxLength) {
      content = content.substring(0, maxLength);
    }
    // set content; we need to trigger the onChange event for React to use the input!
    // this is a hack! see https://lifesaver.codes/answer/trigger-simulated-input-value-change-for-react-16-(after-react-dom-15-6-0-updated)
    const curInput = $jel.get(0);
    const lastValue = curInput.value;
    $jel.val(content);
    curInput._valueTracker?.setValue(lastValue);
    curInput.dispatchEvent(new Event("input", { bubbles: true }));
  }

  addCharacter(char) {
    this.target.focus();
    const selectRange = this.target.selectionStart + 1;
    this.target.value = `${this.target.value.substr(
      0,
      this.target.selectionStart,
    )}${char}${this.target.value.substring(this.target.selectionEnd)}`;
    this.target.setSelectionRange(selectRange, selectRange);
  }

  // backspace buttion click events
  singleClickBackButton() {
    let selectRange;
    this.target.focus();
    if (document.all) {
      try {
        selectRange.select();
      } catch (e) {
        selectRange = document.selection.createRange();
      }
      if (
        selectRange.compareEndPoints("StartToStart", this.target.createTextRange()) === 0 &&
        selectRange.text === ""
      ) {
        return false;
      } else {
        if (selectRange.text === "") {
          selectRange.moveStart("character", -1);
          selectRange.select();
        }
        document.selection.clear();
      }
    } else {
      if (this.target.selectionStart !== this.target.selectionEnd) {
        selectRange = this.target.selectionStart;
        this.target.value = `${this.target.value.substr(
          0,
          this.target.selectionStart,
        )}${this.target.value.substring(this.target.selectionEnd)}`;
      } else {
        selectRange = this.target.selectionStart - 1;
        this.target.value = `${this.target.value.substr(
          0,
          this.target.selectionStart - 1,
        )}${this.target.value.substring(this.target.selectionEnd)}`;
      }
      this.target.setSelectionRange(selectRange, selectRange);
    }
  }
}
