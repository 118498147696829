import * as React from "react";
import { connect } from "react-redux";
import jQuery from "jquery";

import { IAction } from "actions";
import { IState } from "states/states";
import { KeyboardHandler } from "view/comp/keyboardhandler.js";

import "./keyboard.scss";

interface IKeyboardExtProps {}

interface IKeyboardProps {
  updateDummy: number;
}

const mapStateToProps = (state: IState, ownProps: IKeyboardExtProps) => {
  // create new props with every update to ensure running componentDidUpdate
  const props: IKeyboardProps = {
    updateDummy: Date.now(),
  };
  return props;
};

interface IKeyboardDispatch {}

const mapDispatchToProps = (dispatch: (action: IAction<any>) => void) => {
  const dispatchProps: IKeyboardDispatch = {};
  return dispatchProps;
};

export class KeyboardComponent extends React.Component<IKeyboardProps & IKeyboardDispatch> {
  private keyboardText: KeyboardHandler | undefined;
  private keyboardNumber: KeyboardHandler | undefined;
  private keyboardInput: KeyboardHandler | undefined;
  private keyboardPassword: KeyboardHandler | undefined;

  public componentDidMount() {
    if (!this.keyboardInput) {
      // initialize handler after component mouting
      this.keyboardInput = new KeyboardHandler("keyboard_input");
      this.keyboardText = new KeyboardHandler("keyboard_textarea");
      this.keyboardNumber = new KeyboardHandler("keyboard_number");
      this.keyboardPassword = new KeyboardHandler("keyboard_password");
    }
  }

  public componentDidUpdate() {
    jQuery("textarea")
      .off("focus")
      .on("focus", (event) => {
        this.keyboardText?.reveal(event.currentTarget);
      });
    jQuery("input:not([type=number]):not([type=password]):not([type=checkbox])")
      .off("focus")
      .on("focus", (event) => {
        this.keyboardInput?.reveal(event.currentTarget);
      });
    jQuery("input[type=password]")
      .off("focus")
      .on("focus", (event) => {
        this.keyboardPassword?.reveal(event.currentTarget);
      });
    jQuery("input[type=number]")
      .off("focus")
      .on("focus", (event) => {
        this.keyboardNumber?.reveal(event.currentTarget);
      });
  }

  public render() {
    return (
      <>
        {this.renderTextKeyboard()}
        {this.renderInputKeyboard()}
        {this.renderPasswordKeyboard()}
        {this.renderNumberKeyboard()}
      </>
    );
  }

  private renderTestInputs() {
    return (
      <p>
        Text: <input type="text" />
        <br />
        Number: <input type="number" />
        <br />
        Password: <input type="password" />
        <br />
        Textarea: <textarea />
      </p>
    );
  }

  private renderTextKeyboard() {
    return (
      <div id="keyboard_textarea" className="keyboardcontainer containertextarea">
        <textarea className="write" rows={3} cols={180}></textarea>
        <ul className="keyboard">
          <li className="symbol">
            <span className="off">`</span>
            <span className="on">~</span>
          </li>
          <li className="symbol">
            <span className="off">1</span>
            <span className="on">!</span>
          </li>
          <li className="symbol">
            <span className="off">2</span>
            <span className="on">@</span>
          </li>
          <li className="symbol">
            <span className="off">3</span>
            <span className="on">#</span>
          </li>
          <li className="symbol">
            <span className="off">4</span>
            <span className="on">$</span>
          </li>
          <li className="symbol">
            <span className="off">5</span>
            <span className="on">%</span>
          </li>
          <li className="symbol">
            <span className="off">6</span>
            <span className="on">^</span>
          </li>
          <li className="symbol">
            <span className="off">7</span>
            <span className="on">&amp;</span>
          </li>
          <li className="symbol">
            <span className="off">8</span>
            <span className="on">*</span>
          </li>
          <li className="symbol">
            <span className="off">9</span>
            <span className="on">(</span>
          </li>
          <li className="symbol">
            <span className="off">0</span>
            <span className="on">)</span>
          </li>
          <li className="symbol">
            <span className="off">-</span>
            <span className="on">_</span>
          </li>
          <li className="symbol">
            <span className="off">=</span>
            <span className="on">+</span>
          </li>
          <li className="delete lastitem size-delete ">Del</li>
          <li className="tab size-tab">Tab</li>
          <li className="letter">q</li>
          <li className="letter">w</li>
          <li className="letter">e</li>
          <li className="letter">r</li>
          <li className="letter">t</li>
          <li className="letter">y</li>
          <li className="letter">u</li>
          <li className="letter">i</li>
          <li className="letter">o</li>
          <li className="letter">p</li>
          <li className="symbol">
            <span className="off">[</span>
            <span className="on">&#123;</span>
          </li>
          <li className="symbol">
            <span className="off">]</span>
            <span className="on">&#125;</span>
          </li>
          <li className="symbol lastitem">
            <span className="off">\</span>
            <span className="on">|</span>
          </li>
          <li className="capslock left size-capslock">Caps Lock</li>
          <li className="letter">a</li>
          <li className="letter">s</li>
          <li className="letter">d</li>
          <li className="letter">f</li>
          <li className="letter">g</li>
          <li className="letter">h</li>
          <li className="letter">j</li>
          <li className="letter">k</li>
          <li className="letter">l</li>
          <li className="symbol">
            <span className="off">;</span>
            <span className="on">:</span>
          </li>
          <li className="symbol">
            <span className="off">&apos;</span>
            <span className="on">&quot;</span>
          </li>
          <li className="return lastitem size-return">Enter</li>
          <li className="size-left-shift shift left size-2letter">Shift</li>
          <li className="letter">z</li>
          <li className="letter">x</li>
          <li className="letter">c</li>
          <li className="letter">v</li>
          <li className="letter">b</li>
          <li className="letter">n</li>
          <li className="letter">m</li>
          <li className="symbol">
            <span className="off">,</span>
            <span className="on">&lt;</span>
          </li>
          <li className="symbol">
            <span className="off">.</span>
            <span className="on">&gt;</span>
          </li>
          <li className="symbol">
            <span className="off">/</span>
            <span className="on">?</span>
          </li>
          <li className="size-right-shift shift lastitem size-2letter">Shift</li>
          <li className="accept size-accept">Accept</li>
          <li className="space size-space">&nbsp;</li>
          <li className="cancel size-cancel">Cancel</li>
        </ul>
      </div>
    );
  }

  private renderInputKeyboard() {
    return (
      <div id="keyboard_input" className="keyboardcontainer containertext">
        <input className="write" type="text" />
        <ul className="keyboard">
          <li className="symbol">
            <span className="off">`</span>
            <span className="on">~</span>
          </li>
          <li className="symbol">
            <span className="off">1</span>
            <span className="on">!</span>
          </li>
          <li className="symbol">
            <span className="off">2</span>
            <span className="on">@</span>
          </li>
          <li className="symbol">
            <span className="off">3</span>
            <span className="on">#</span>
          </li>
          <li className="symbol">
            <span className="off">4</span>
            <span className="on">$</span>
          </li>
          <li className="symbol">
            <span className="off">5</span>
            <span className="on">%</span>
          </li>
          <li className="symbol">
            <span className="off">6</span>
            <span className="on">^</span>
          </li>
          <li className="symbol">
            <span className="off">7</span>
            <span className="on">&amp;</span>
          </li>
          <li className="symbol">
            <span className="off">8</span>
            <span className="on">*</span>
          </li>
          <li className="symbol">
            <span className="off">9</span>
            <span className="on">(</span>
          </li>
          <li className="symbol">
            <span className="off">0</span>
            <span className="on">)</span>
          </li>
          <li className="symbol">
            <span className="off">-</span>
            <span className="on">_</span>
          </li>
          <li className="symbol">
            <span className="off">=</span>
            <span className="on">+</span>
          </li>
          <li className="delete lastitem size-delete ">Del</li>
          <li className="tab size-tab">Tab</li>
          <li className="letter">q</li>
          <li className="letter">w</li>
          <li className="letter">e</li>
          <li className="letter">r</li>
          <li className="letter">t</li>
          <li className="letter">y</li>
          <li className="letter">u</li>
          <li className="letter">i</li>
          <li className="letter">o</li>
          <li className="letter">p</li>
          <li className="symbol">
            <span className="off">[</span>
            <span className="on">&#123;</span>
          </li>
          <li className="symbol">
            <span className="off">]</span>
            <span className="on">&#125;</span>
          </li>
          <li className="symbol lastitem">
            <span className="off">\</span>
            <span className="on">|</span>
          </li>
          <li className="capslock left size-capslock">Caps Lock</li>
          <li className="letter">a</li>
          <li className="letter">s</li>
          <li className="letter">d</li>
          <li className="letter">f</li>
          <li className="letter">g</li>
          <li className="letter">h</li>
          <li className="letter">j</li>
          <li className="letter">k</li>
          <li className="letter">l</li>
          <li className="symbol">
            <span className="off">;</span>
            <span className="on">:</span>
          </li>
          <li className="symbol">
            <span className="off">&apos;</span>
            <span className="on">&quot;</span>
          </li>
          <li className="accept lastitem size-return">Enter</li>
          <li className="size-left-shift shift left size-2letter">Shift</li>
          <li className="letter">z</li>
          <li className="letter">x</li>
          <li className="letter">c</li>
          <li className="letter">v</li>
          <li className="letter">b</li>
          <li className="letter">n</li>
          <li className="letter">m</li>
          <li className="symbol">
            <span className="off">,</span>
            <span className="on">&lt;</span>
          </li>
          <li className="symbol">
            <span className="off">.</span>
            <span className="on">&gt;</span>
          </li>
          <li className="symbol">
            <span className="off">/</span>
            <span className="on">?</span>
          </li>
          <li className="size-right-shift shift lastitem size-2letter">Shift</li>
          <li className="accept size-accept">Accept</li>
          <li className="space size-space">&nbsp;</li>
          <li className="cancel size-cancel">Cancel</li>
        </ul>
      </div>
    );
  }

  private renderPasswordKeyboard() {
    return (
      <div id="keyboard_password" className="keyboardcontainer containertext">
        <input className="write" type="password" />
        <ul className="keyboard">
          <li className="symbol">
            <span className="off">`</span>
            <span className="on">~</span>
          </li>
          <li className="symbol">
            <span className="off">1</span>
            <span className="on">!</span>
          </li>
          <li className="symbol">
            <span className="off">2</span>
            <span className="on">@</span>
          </li>
          <li className="symbol">
            <span className="off">3</span>
            <span className="on">#</span>
          </li>
          <li className="symbol">
            <span className="off">4</span>
            <span className="on">$</span>
          </li>
          <li className="symbol">
            <span className="off">5</span>
            <span className="on">%</span>
          </li>
          <li className="symbol">
            <span className="off">6</span>
            <span className="on">^</span>
          </li>
          <li className="symbol">
            <span className="off">7</span>
            <span className="on">&amp;</span>
          </li>
          <li className="symbol">
            <span className="off">8</span>
            <span className="on">*</span>
          </li>
          <li className="symbol">
            <span className="off">9</span>
            <span className="on">(</span>
          </li>
          <li className="symbol">
            <span className="off">0</span>
            <span className="on">)</span>
          </li>
          <li className="symbol">
            <span className="off">-</span>
            <span className="on">_</span>
          </li>
          <li className="symbol">
            <span className="off">=</span>
            <span className="on">+</span>
          </li>
          <li className="delete lastitem size-delete ">Del</li>
          <li className="tab size-tab">Tab</li>
          <li className="letter">q</li>
          <li className="letter">w</li>
          <li className="letter">e</li>
          <li className="letter">r</li>
          <li className="letter">t</li>
          <li className="letter">y</li>
          <li className="letter">u</li>
          <li className="letter">i</li>
          <li className="letter">o</li>
          <li className="letter">p</li>
          <li className="symbol">
            <span className="off">[</span>
            <span className="on">&#123;</span>
          </li>
          <li className="symbol">
            <span className="off">]</span>
            <span className="on">&#125;</span>
          </li>
          <li className="symbol lastitem">
            <span className="off">\</span>
            <span className="on">|</span>
          </li>
          <li className="capslock left size-capslock">Caps Lock</li>
          <li className="letter">a</li>
          <li className="letter">s</li>
          <li className="letter">d</li>
          <li className="letter">f</li>
          <li className="letter">g</li>
          <li className="letter">h</li>
          <li className="letter">j</li>
          <li className="letter">k</li>
          <li className="letter">l</li>
          <li className="symbol">
            <span className="off">;</span>
            <span className="on">:</span>
          </li>
          <li className="symbol">
            <span className="off">&apos;</span>
            <span className="on">&quot;</span>
          </li>
          <li className="accept lastitem size-return">Enter</li>
          <li className="size-left-shift shift left size-2letter">Shift</li>
          <li className="letter">z</li>
          <li className="letter">x</li>
          <li className="letter">c</li>
          <li className="letter">v</li>
          <li className="letter">b</li>
          <li className="letter">n</li>
          <li className="letter">m</li>
          <li className="symbol">
            <span className="off">,</span>
            <span className="on">&lt;</span>
          </li>
          <li className="symbol">
            <span className="off">.</span>
            <span className="on">&gt;</span>
          </li>
          <li className="symbol">
            <span className="off">/</span>
            <span className="on">?</span>
          </li>
          <li className="size-right-shift shift lastitem size-2letter">Shift</li>
          <li className="accept size-accept">Accept</li>
          <li className="space size-space">&nbsp;</li>
          <li className="cancel size-cancel">Cancel</li>
        </ul>
      </div>
    );
  }

  private renderNumberKeyboard() {
    return (
      <div id="keyboard_number" className="keyboardcontainer containernumber">
        <input className="write" type="text" id="number" />
        <ul className="keyboard">
          <li className="left">7</li>
          <li className="">8</li>
          <li className="">9</li>
          <li className="delete">&larr;</li>
          <li className="left">4</li>
          <li className="">5</li>
          <li className="">6</li>
          <li className="changesign">+/-</li>
          <li className="left">1</li>
          <li className="">2</li>
          <li className="">3</li>
          <li className="cancel">Canc</li>
          <li className="left">0</li>
          <li className="maxone">.</li>
          <li className="accept size-2letter">Enter</li>
        </ul>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyboardComponent);
